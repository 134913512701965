import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StHeader = styled.div`
  margin: 0 auto 30px auto;
  width: 100%;
  padding: 0 16px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: ${COLORS.gray3};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 auto 48px auto;
    padding: 0;
    width: 544px;
    font-size: 44px;
    line-height: 48px;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1000px;
  }
`;

export const StText = styled.div`
  margin: 0 auto 20px auto;
  padding: 0 16px;
  width: 100%;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${COLORS.gray3};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    text-align: unset;
    width: 544px;
    font-size: 24px;
    line-height: 32px;
    margin: 0 auto 30px auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 1000px;
    margin: 0 auto 60px auto;
  }
`;
