import { useHistory } from 'react-router-dom';

import {
  AboutProjectPageApi,
  ActivityApi,
  ApplicationApi,
  ApplicationFormsApi,
  BankingApplicationsApi,
  BankingProductsApi,
  BasePageApi,
  CaptchaApi,
  CartApi,
  CategoriesApi,
  CityApi,
  CloseErrorScreenApi,
  ProductsCommentsApi,
  CompanyApi,
  CreatorsPageApi,
  DirectionsApi,
  DocumentsApi,
  DynamicApplicationsApi,
  EmailModalsApi,
  ErrorScreensApi,
  FeedbackApi,
  FeedbackTopicApi,
  FinanceCategoryApi,
  FooterApi,
  FooterContactsApi,
  FooterSocialsApi,
  GloabalCategoryPageApi,
  GlobalCategoriesApi,
  GlobalProductsApi,
  GuideCategoryApi,
  GuideCommentsApi,
  GuideDirectionsApi,
  GuidePageApi,
  GuideRatingApi,
  HobbyApi,
  ImagesApi,
  InsuranceCategoryApi,
  InsuranceProductsApi,
  LdapApi,
  MainBannersApi,
  MainNewsPageApi,
  MainPageApi,
  MediaApplicationsApi,
  MediaCategoryApi,
  MediaProductsApi,
  MedicineCategoryApi,
  MedicineCentersApi,
  MedicineDirectionsApi,
  MedicineProgramsApi,
  MidicinePageApi,
  NewAboutProjectPageApi,
  NewsCommentsApi,
  NewsPageApi,
  NewsSectionsApi,
  OauthApi,
  OurPeopleArticlesApi,
  PageContentApi,
  PortalDirectionItemsApi,
  PreferencesApi,
  ProductAttributeApi,
  ProductPagePreferencesApi,
  ProductRatingsApi,
  ProductsApi,
  ProfileApi,
  ProjectsApi,
  PromocodeApi,
  RestaurantScheduleApi,
  SearchApi,
  ShopsApi,
  SigninApi,
  SignupApi,
  SmallBannerApi,
  ToursScheduleApi,
  UserAgreementApi,
  VideoHistoriesApi,
  WineCartInstuctionApi,
  MainApplicationsApi,
} from 'services';

export const getCookie = (name: string): string | null => {
  if (document.cookie.length === 0) return null;

  const cookie = document.cookie.split(';').find((el) => {
    return el.trim().startsWith(`${name}=`);
  });

  return cookie ? decodeURIComponent(cookie.trim().substring(name.length + 1)) : null;
};

export const useLink = () => {
  const history = useHistory();
  return (link: string) => {
    link.startsWith('/') ? history.push(link) : (window.location.href = link);
  };
};

export const api = Object.freeze({
  comments: new ProductsCommentsApi(),
  feedback: {
    inner: new FeedbackApi(),
    topic: new FeedbackTopicApi(),
  },
  mainBanners: new MainBannersApi(),
  products: new ProductsApi(),
  globalProducts: new GlobalProductsApi(),
  productAttribute: new ProductAttributeApi(),
  productsRating: new ProductRatingsApi(),
  profile: new ProfileApi(),
  shops: new ShopsApi(),
  auth: {
    signIn: new SigninApi(),
    signUp: new SignupApi(),
  },
  images: new ImagesApi(),
  hobbies: new HobbyApi(),
  activities: new ActivityApi(),
  cities: new CityApi(),
  categories: new CategoriesApi(),
  company: new CompanyApi(),
  footer: new FooterApi(),
  footerContacts: new FooterContactsApi(),
  footerSocials: new FooterSocialsApi(),
  globalCategories: new GlobalCategoriesApi(),
  globalCategoriesPage: new GloabalCategoryPageApi(),
  mainPage: new MainPageApi(),
  portalDirections: new PortalDirectionItemsApi(),
  bankingProducts: new BankingProductsApi(),
  insuranceProducts: new InsuranceProductsApi(),
  mediaProducts: new MediaProductsApi(),
  financeCategory: new FinanceCategoryApi(),
  insuranceCategory: new InsuranceCategoryApi(),
  mediaCategory: new MediaCategoryApi(),
  promocode: new PromocodeApi(),
  cart: new CartApi(),
  wineCartInstruction: new WineCartInstuctionApi(),
  news: {
    mainPage: new MainNewsPageApi(),
    pages: new NewsPageApi(),
    comments: new NewsCommentsApi(),
    newsSections: new NewsSectionsApi(),
  },
  userAgreement: new UserAgreementApi(),
  guide: {
    category: new GuideCategoryApi(),
    pages: new GuidePageApi(),
    rating: new GuideRatingApi(),
    comments: new GuideCommentsApi(),
  },
  search: new SearchApi(),
  documents: new DocumentsApi(),
  applications: new ApplicationApi(),
  mediaApplications: new MediaApplicationsApi(),
  bankingApplications: new BankingApplicationsApi(),
  mainApplication: new MainApplicationsApi(),
  basePage: new BasePageApi(),
  aboutProjectPage: new AboutProjectPageApi(),
  newAboutProjectPage: new NewAboutProjectPageApi(),
  productPagePreferences: new ProductPagePreferencesApi(),
  preferences: new PreferencesApi(),
  oauth: new OauthApi(),
  pageContent: new PageContentApi(),
  applicationForm: new ApplicationFormsApi(),
  dynamicApplications: new DynamicApplicationsApi(),
  closeErrorScreen: new CloseErrorScreenApi(),
  errorScreens: new ErrorScreensApi(),
  projects: new ProjectsApi(),
  smallBanner: new SmallBannerApi(),
  restaurantSheldue: new RestaurantScheduleApi(),
  toursSchedule: new ToursScheduleApi(),
  medicine: new MedicineCategoryApi(),
  medicinePrograms: new MedicineProgramsApi(),
  medicineCenters: new MedicineCentersApi(),
  medicinePage: new MidicinePageApi(),
  medicineDirections: new MedicineDirectionsApi(),
  directions: new DirectionsApi(),
  emailModalsApi: new EmailModalsApi(),
  videoHistoriesApi: new VideoHistoriesApi(),
  guideDirections: new GuideDirectionsApi(),
  ldapToken: new LdapApi(),
  ourPeopleArticles: new OurPeopleArticlesApi(),
  captcha: new CaptchaApi(),
  creatorsPage: new CreatorsPageApi(),
});
