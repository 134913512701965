import React, { useCallback, useState } from 'react';

import { HtmlRenderer } from 'utils/htmlParser';
import { Button } from 'shared/components';
import { debounce } from 'lodash';
import { useMainPageStore } from 'store';
import { useLink } from 'utils/api';
import { ItemWrapper, Content, Title, Description, Image, Dot, Dots, ImageWrapper } from './styles';
import { ActionType, ItemProps } from './types';

export const Item: React.FC<ItemProps> = (props) => {
  const mainPageStore = useMainPageStore();
  const openLink = useLink();

  const showButton =
    (props.buttonAction === ActionType.Link && props.link.link) ||
    (props.buttonAction === ActionType.ApplicationForm && props.dynamicForm);

  const handleFormClick = useCallback(async () => {
    await mainPageStore.emailModalStore.fetchContent();
    mainPageStore.emailModalStore.setShow(true);
  }, []);

  const handleLinkClick = useCallback(() => {
    openLink(props.link.link);
  }, []);

  const onButtonClick = useCallback(() => {
    if (props.buttonAction === ActionType.Link && !props.showModal) {
      handleLinkClick();
    } else {
      handleFormClick();
    }
  }, [props.buttonAction]);

  return (
    <ItemWrapper>
      <Content>
        <Title>{props.title}</Title>
        <Description>
          <HtmlRenderer html={props.description} />
        </Description>
        {showButton && <Button onClick={onButtonClick}>{props.link.text}</Button>}
        <Dots>
          {Array(props.slidesCount)
            .fill(0)
            .map((item, index) => (
              <Dot key={index} active={index === props.currentIndex} onClick={() => props.onClick(index)} />
            ))}
        </Dots>
      </Content>
      <ImageWrapper>
        <Image src={props.image} alt="banner image" />
      </ImageWrapper>
    </ItemWrapper>
  );
};
