import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div<{ withNews: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin: 0 88px;
    gap: 30px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: ${(props) => (props.withNews ? '50%' : '100%')};
    margin: ${(props) => (props.withNews ? '0 0 0 136px' : '0 136px')};
  }
`;

export const ApplicationsWrapper = styled.div`
  background: ${COLORS.azureLight};
  padding: 32px 16px;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    border-radius: 16px;
    padding: 32px 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 100%;
  }
`;

export const ApplicationsScroll = styled.div`
  max-height: 577px;
  overflow-y: auto;
  padding: 8px 16px 8px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;

  scrollbar-width: thin;
  scrollbar-color: #bfe9e9 #5cb6c2; /* цвет бегунка и зоны отслеживания */

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    max-height: 712px;
    padding-right: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    height: 100%;
    max-height: 1100px;
    gap: 20px;
  }

  @media screen and (min-width: 1832px) {
    max-height: 712px;
  }
`;

export const Title = styled.div`
  padding: 0 16px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 30px;
    line-height: 34px;
  }
`;
