import styled from 'styled-components';
import { COLORS } from 'utils/constants';
import Icon from 'components/base-ui/icon';

export const Container = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ReplyButton = styled.div`
  color: ${COLORS.secondary};
  font-size: 12px;
  font-weight: 400;
  &:hover {
    color: ${COLORS.primary};
    cursor: pointer;
  }
`;

export const Like = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${COLORS.white};
  font-size: 14px;
  padding: 0 7px;
`;

export const LikeIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  padding-right: 5px;
`;
