import { BREAKPOINTS, COLORS } from 'utils/constants';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 316px;
    gap: 10px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 290px;
  }
`;

export const ImageWrapper = styled.div<{ bg?: string }>`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: block;
    width: 316px;
    height: 181px;
    border-radius: 16px;
    background: ${(props) => `url(${props.bg})`};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 290px;
    height: 166px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${COLORS.blueDark};
`;

export const Date = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${COLORS.blueGray};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-weight: 300;
  }
`;
