import { HeadingPreferences } from 'web';

export const COLORS = {
  azure: '#007493',
  azureLight: '#4d9eb4',
  greenGray: '#e7eff5',
  blueGray: '#8798B0',
  placeholderGray: '#bfcad2',
  azureGradient: 'linear-gradient(270deg, #0C7896 0%, #59C1CF 100%)',
  black: '#000000',
  white: '#FFFFFF',
  gray: '#606060',
  gray1: '#1F1F24',
  gray2: '#464A53',
  gray3: '#767D89',
  gray4: '#A9AEBA',
  gray5: '#D9DBDF',
  gray6: '#E9EAED',
  gray7: '#F3F3F3',
  gray8: '#F3F5F7',
  gray9: '#686b72',
  blueDark: '#3B5275',

  // Старые цвета, стараемся не использовать
  primary: '#4e9eb4',
  primary1: '#8798B0',
  secondary: '#005b70',
  secondary1: '#3B5275',
  linearGradient: 'linear-gradient(104.63deg, #4e9eb4 18.24%, #007493 102.17%)',
  red: '#C31B1C',
  red2: '#861717',
  red3: '#b93b3b',
  cloud: '#D4D4D4',
  cloud2: '#C4C4C4',
  copper: '#A79C75',
  copperLight: '#D4D4D4',
  borderGray: '#D9D9D9',
};

// For using with min-width media query
export const BREAKPOINTS = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1366px',
  desktop: '1600px',
  wideDesktop: '1920px',
  mobileLandscape: '400px',
};

export const REGEXP = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  number: /^[0-9]+$/,
  onlyCyrillic: /^([А-Яа-яЁё]{0,50})$/u,
  phoneNumber: /^\+?[78][-(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/u,
};

export const HEADER_HEIGHT = '57px';

export const PAGE_LIMIT = 12;

export const INITIAL_PAGE = 1;

export const UNICODE_CYRILLIC_OFFSET = 1040;

export const HTTP_STATUSES = {
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  THROTTLE: 429,
};

export const CLOSED_PARAGRAPH_HEIGHT = 122;

export const BASIC_FONT_FAMILY = 'Geologica, sans-serif';

export const DEFAULT_PREFERENCES: Readonly<HeadingPreferences> = {
  articlesTitle: 'Статьи',
  mayInterestTitle: 'Вас может заинтересовать',
};

export const UNDER_CONSTRUCTION_LINK = '/under-construction';
export const FEEDBACK_MODAL_LINK = '/feedback-modal';

export const CURRENT_DATE = new Date();
export const CURRENT_YEAR = CURRENT_DATE.getFullYear();
