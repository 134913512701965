import React, { useMemo, useState } from 'react';
import { Button } from 'shared/components/button';
import { ButtonTypes } from 'shared/components/button/types';
import Section from 'components/base-ui/section';
import { useDeviceType } from 'utils/hooks';
import { StTitle, VideoGallery, ButtonsWrapper, Wrapper } from './styles';
import { Video, VideoBandProps } from './types';
import { Card } from './components';

const MOBILE_CARDS_LIMIT = 6;
const DESKTOP_CARDS_LIMIT = 8;

export const VideoBand: React.FC<VideoBandProps> = ({ data }) => {
  const device = useDeviceType();
  const limit = useMemo(() => {
    return device === 'mobile' || device === 'tablet' ? MOBILE_CARDS_LIMIT : DESKTOP_CARDS_LIMIT;
  }, [device]);

  const [currentCards, setCurrentCards] = useState<Video[]>(data.videos.slice(0, limit));

  const onMore = () => {
    setCurrentCards(currentCards.length <= limit ? data.videos : data.videos.slice(0, limit));
  };

  return (
    <Section compact>
      <Wrapper>
        <StTitle>{data.title}</StTitle>
        <VideoGallery>
          {currentCards.map((video, index) => (
            <Card key={index} {...video} />
          ))}
        </VideoGallery>
        {data.videos.length > limit && (
          <ButtonsWrapper>
            <Button type={ButtonTypes.Secondary} onClick={onMore}>
              {currentCards.length <= limit ? 'Больше видео' : 'Меньше видео'}
            </Button>
          </ButtonsWrapper>
        )}
      </Wrapper>
    </Section>
  );
};
