import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { Container } from '../../base-ui/container';
import Icon from '../../base-ui/icon';

export const ButtonWrapper = styled.div<{ hasBanner: boolean, isVisible: boolean}>`
    .go-to-comments-button {
        width: 48px;
        height: 48px;
        img {
            width: ${({ isVisible }) => (isVisible ? '25px' : '15px')};
            transition: all 0.4s ease-out;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.mobile}) {
        display: inline-block;
        position: fixed;
        //top: ${({ hasBanner }) => (hasBanner ? 'calc(100vh - 100px)' : '400px')};
        top: ${({ hasBanner }) => (hasBanner ? '520px' : '400px')};
        left: calc(100% - 56px);
        z-index: 10000;
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        display: inline-block;
        position: fixed;
        z-index: 10000;
        //top: ${({ hasBanner }) => (hasBanner ? 'calc(100vh - 100px)' : '400px')};
        top: ${({ hasBanner }) => (hasBanner ? '520px' : '400px')};
        left: calc(100% - 56px);
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      display: inline-block;
      position: sticky;
      z-index: 10000;
      top: 650px;
      left: 95%;

      .go-to-comments-button {
          width: 60px;
          height: 60px;
          img {
              width: 25px;
              transition: all 0.4s ease-out;
          }

          :hover {
              img {
                  width: 35px;
                  transition: all 0.4s ease-out;
              }
          }
      }
    }
    transition: all 0.4s ease-out;
`;

export const StContainer = styled.div<{ hasBanner: boolean}>`
    position: absolute;
    width: 100%;
    height: ${({ hasBanner }) => (hasBanner ? 'calc(100% - 900px)' : 'fit-content')};
    top: ${({ hasBanner }) => (hasBanner ? '650px' : '400px')};
`;

export const StCommentContainer = styled.div<{ hasBanner: boolean}>`
    display: block ;
    width: 0px;
    opacity: 1;
    height: 460px;
    background: white;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0px 4px 10px 0px #00000059;

    top: ${({ hasBanner }) => (hasBanner ? '-420px' : '-200px')};
    left: 500px;
    position: relative;

    button {
        font-size: 14px;
    }

    transition: opacity 0.7s, width 0.3s, left 0.6s ease-out;

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        height: 368px;
        top: -320px;
    }
`;

export const StCloseBlock = styled.div`
    width: 100%;
    height: 58px;
    padding: 16px 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${COLORS.white};
    font-size: 20px;
    background: linear-gradient(270deg, #0C7896 0%, #59C1CF 100%);
    border-radius: 4px;

    img {
        width: 24px;
        fill: ${COLORS.white};
    }

`;

export const StFooterContainer = styled(Container)`
    width: 100%;
    justify-content: space-between;

    @media screen and (min-width: ${BREAKPOINTS.mobile}) {
        flex-direction: column;
        gap: 16px;
        button {
            width: 100%;
        }
    }

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
        flex-direction: row;
        button {
            width: fit-content;
        }
    }
`;

export const StIcon = styled(Icon)`
  cursor: pointer;
`;
