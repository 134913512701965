import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const Container = styled.div<{ withApps: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  gap: 30px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: ${(props) => (props.withApps ? '50%' : '100%')};
    margin: ${(props) => (props.withApps ? '0 136px 0 0' : '0 136px')};
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  font-weight: 400;
  color: ${COLORS.azure};
  border-bottom: 1px solid ${COLORS.azure};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 28px;
    line-height: 32px;
    border: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    justify-content: flex-end;
  }
`;
