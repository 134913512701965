import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 50%;
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    object-fit: cover;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 46px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    align-items: flex-start;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    gap: 96px;
    width: 50%;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  color: ${COLORS.azure};
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 52px;
    font-weight: 700;
  }
  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    font-size: 52px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${COLORS.azure};
  > p {
    margin: 0;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: space-between;
  gap: 40px;
  margin-top: 40px;
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    margin-top: 80px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    gap: 20px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    gap: 20px;
    margin-top: 80px;
  }
`;

export const BottomTitle = styled(Title)`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    min-width: 30%;
    max-width: 50%;
    overflow: hidden;
  }
`;

export const BottomText = styled(Description)<{ w: number }>`
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: ${(props) => `${props.w}%`};
  }
`;
