import {
  AboutOurPeopleType,
  AboutPartnersBlockType,
  AboutProjectGroupsBlockType,
  ArticleImageBlock,
  ChronologyCarouselBlock,
  DoubleBannerBlockContent,
  FrameAdaptiveSize,
  FreeContentValue,
  GalleryBlockContent,
  GuidePreview,
  HugeSliderValue,
  IApplicationFormBlock,
  ImageWithDescription,
  MediaOfferValue,
  ParagraphValue,
  RightArticleBlock,
  ServicesBannerValue,
} from 'web';

import { VideoItemType } from 'store/projects/types';
import { SimpleBannerType } from '../components/medicine/ui/SimpleBanner/types';

export enum BlockTypes {
  HugeSlider = 'huge_slider',
  PortalDirection = 'portal_direction',
  GroupCompanies = 'group_companies',
  Paragraph = 'paragraph',
  ServicesBanner = 'services_banner',
  Products = 'products',
  FreeContent = 'free_content',
  Categories = 'categories',
  MoreTvOffer = 'more_tv_offer_info',
  SportExpressOffer = 'sport_express_offer_info',
  NewsMainImage = 'main_image',
  NewsMainContent = 'main_content',
  Preview = 'preview',
  PreviewImage = 'preview_image',
  Gallery = 'galery',
  Header = 'header',
  ImageWithDescription = 'image_with_description',
  Steps = 'steps',
  DoubleBanner = 'double_banner',
  Tab = 'tab',
  IconWithText = 'icon_with_text',
  Text = 'text',
  GuidePages = 'guid_pages',
  GuideMainContent = 'guide_main_content',
  GuideMainImage = 'guide_main_image',
  GuideMainPageImage = 'guide_main_page_image',
  IframeLink = 'iframe_link',
  NewsPreview = 'news_preview',
  ApplicationPreview = 'application_preview',
  Collapse = 'collapse',
  AboutProjectBanner = 'about_project_banner',
  NewProjectBanner = 'new_project_banner',
  AboutCenterText = 'about_center_text',
  AboutCenterHeader = 'about_center_header',
  AboutImageGallery = 'about_image_gallery',
  AboutColumns = 'about_columns',
  AboutPartners = 'about_partners',
  AboutProjectGroups = 'about_project_groups',
  AboutOurPeople = 'about_our_people',
  CompanyGroupAnimated = 'company_group_animated',
  AboutPortalDirections = 'about_portal_directions',
  TravelLineWidget = 'travel_line_widget',
  NewsPages = 'news_pages',
  Email = 'email',
  FilterHeader = 'filter_header',
  Cards = 'cards',
  Chronology = 'chronology',
  PhotoCarousel = 'photo_carousel',
  BigBanner = 'big_banner',
  SmallBanner = 'small_banner',
  ArticleBlockRight = 'i2',
  BigHeader = 'big_header',
  ImageBlock = 'image',
  MagazineArticleBanner = 'banner',
  IFrameSize = 'iframe_size',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MainImage = 'main_image',
  SimpleBanner = 'simple_banner',
  Directions = 'with_directions',
  WithSorting = 'with_sorting',
  MainPageImage = 'main_page_image',
  GuidePreview = 'guid_preview',
  VideoWithText = 'video_with_text',
  VideoGallery = 'video_gallery',
  ApplicationFormBlock = 'application_form_block',
  CreatorsBanner = 'creators_banner',
  CreatorsAboutProgram = 'creators_about_program',
  CreatorsImage = 'creators_image',
  CreatorsHowItWas = 'creators_how_it_was',
  CreatorsListingColumns = 'creators_listing_columns',
  CreatorsYears = 'creators_years',
  CreatorsImages = 'creators_images',
  VideoBelt = 'video_belt',
}

export interface BaseBlock<T extends BlockTypes, V> {
  id: string;
  type: T;
  value: V;
}

interface VideoBlockType {
  header: string;
  text: string;
  video: VideoItemType;
}

type HugeSliderBlock = BaseBlock<
  BlockTypes.HugeSlider,
  {
    images: HugeSliderValue[];
    isActive: boolean | null;
    periodicity: number | null;
    type: 'animated' | 'slides';
    file: 'string';
  }
>;

type PortalDirectionBlock = BaseBlock<BlockTypes.PortalDirection, boolean>;

type GroupCompaniesBlock = BaseBlock<BlockTypes.GroupCompanies, boolean>;

export type ParagraphBlock = BaseBlock<BlockTypes.Paragraph, ParagraphValue>;

export type ServicesBannerBlock = BaseBlock<BlockTypes.ServicesBanner, ServicesBannerValue>;

type ProductsBlock = BaseBlock<BlockTypes.Products, boolean>;

type FreeContentBlock = BaseBlock<BlockTypes.FreeContent, FreeContentValue>;

type CategoriesBlock = BaseBlock<BlockTypes.Categories, boolean>;

type MoreTvOfferBlock = BaseBlock<BlockTypes.MoreTvOffer, MediaOfferValue>;

type SportExpressOfferBlock = BaseBlock<BlockTypes.SportExpressOffer, MediaOfferValue>;

type NewsMainImageBlock = BaseBlock<BlockTypes.NewsMainImage, string>;

type NewsMainContentBlock = BaseBlock<BlockTypes.NewsMainContent, ParagraphValue>;

export type NewsPreviewBlock = BaseBlock<BlockTypes.Preview, ParagraphValue>;

export type NewPreviewImageBlock = BaseBlock<BlockTypes.PreviewImage, string>;

export type NewsPagesBlock = BaseBlock<BlockTypes.NewsPages, boolean>;

type GalleryBlock = BaseBlock<BlockTypes.Gallery, GalleryBlockContent>;

type HeaderBlock = BaseBlock<BlockTypes.Header, string>;

type ImageWithDescriptionBlock = BaseBlock<BlockTypes.ImageWithDescription, ImageWithDescription>;

type StepsBlock = BaseBlock<BlockTypes.Steps, string[]>;

type DoubleBannerBlock = BaseBlock<BlockTypes.DoubleBanner, DoubleBannerBlockContent>;

type TabBlock = BaseBlock<BlockTypes.Tab, ParagraphValue[]>;

type IconWithTextBlock = BaseBlock<BlockTypes.IconWithText, ImageWithDescription[]>;

type TextBlock = BaseBlock<BlockTypes.Text, string>;

type GuidePagesBlock = BaseBlock<BlockTypes.GuidePages, boolean>;

type GuideMainImageBlock = BaseBlock<BlockTypes.GuideMainImage, string>;

type GuideMainContentBlock = BaseBlock<BlockTypes.GuideMainContent, ParagraphValue>;

type IframeLinkBlock = BaseBlock<BlockTypes.IframeLink, string>;

type NewsMainPagePreview = BaseBlock<BlockTypes.NewsPreview, boolean>;

type ApplicationMainPagePreview = BaseBlock<BlockTypes.ApplicationPreview, boolean>;

type CollapseBlock = BaseBlock<BlockTypes.Collapse, { header: string; content: Block[] }>;

type AboutProjectBannerBlock = BaseBlock<BlockTypes.AboutProjectBanner, { text: string; image: string }>;

type NewProjectBannerBlock = BaseBlock<BlockTypes.NewProjectBanner, { preview: string; image: string; header: string }>;

type AboutColumnsBlock = BaseBlock<
  BlockTypes.AboutColumns,
  { header: string; columns: { header: string; text: string }[] }
>;

type AboutPartnersBlock = BaseBlock<BlockTypes.AboutPartners, AboutPartnersBlockType>;

type AboutProjectGroupsBlock = BaseBlock<BlockTypes.AboutProjectGroups, AboutProjectGroupsBlockType>;

type AboutOurPeopleBlock = BaseBlock<BlockTypes.AboutOurPeople, AboutOurPeopleType>;

type CompanyGroupAnimatedBlock = BaseBlock<BlockTypes.CompanyGroupAnimated, boolean>;

type AboutPortalDirectionsBlock = BaseBlock<BlockTypes.AboutPortalDirections, boolean>;

export type TravelLineWidgetBlock = BaseBlock<BlockTypes.TravelLineWidget, boolean>;

type CardsBlock = BaseBlock<BlockTypes.Cards, boolean>;

type ChronologyBlock = BaseBlock<BlockTypes.Chronology, ChronologyCarouselBlock>;

export type PhotoCarouselBlock = BaseBlock<BlockTypes.PhotoCarousel, { image: string[]; title: string }>;

type BigBannerBlock = BaseBlock<BlockTypes.BigBanner, boolean>;

type SmallBannerBlock = BaseBlock<BlockTypes.SmallBanner, boolean>;

type ArticleRightBlock = BaseBlock<BlockTypes.ArticleBlockRight, RightArticleBlock>;

type BigHeaderBlock = BaseBlock<BlockTypes.BigHeader, string>;

type ImageBlock = BaseBlock<BlockTypes.ImageBlock, ArticleImageBlock>;

type FilterHeaderBlock = BaseBlock<BlockTypes.FilterHeader, boolean>;

type MagazineArticleBanner = BaseBlock<BlockTypes.MagazineArticleBanner, boolean>;

type IFrameSize = BaseBlock<BlockTypes.IFrameSize, FrameAdaptiveSize>;

export type SimpleBannerBlock = BaseBlock<BlockTypes.SimpleBanner, SimpleBannerType>;

export type DirectionsBlock = BaseBlock<BlockTypes.Directions, boolean>;

export type WithSortingBlock = BaseBlock<BlockTypes.WithSorting, boolean>;

export type MainPageImageBlock = BaseBlock<BlockTypes.MainPageImage, boolean>;

export type GuidePreviewBlock = BaseBlock<BlockTypes.GuidePreview, GuidePreview>;

export type GuideMainPageImageBlock = BaseBlock<BlockTypes.GuideMainPageImage, string>;

export type AboutCenterTextBlock = BaseBlock<BlockTypes.AboutCenterText, string>;

export type AboutCenterHeaderBlock = BaseBlock<BlockTypes.AboutCenterHeader, string>;

export type AboutImageGalleryBlock = BaseBlock<BlockTypes.AboutImageGallery, { imageItems: { image: string }[] }>;

export type VideoWithTextBlock = BaseBlock<
  BlockTypes.VideoWithText,
  {
    header: string;
    text: string;
    video: VideoItemType;
  }
>;

export type VideoGalleryBlock = BaseBlock<
  BlockTypes.VideoGallery,
  {
    header: string;
    videos: VideoItemType[];
  }
>;

export type VideoBeltBlockType = BaseBlock<
  BlockTypes.VideoBelt,
  {
    title: string;
    videos: { cardDescription?: string; fullDescription?: string; video: VideoItemType; title?: string }[];
  }
>;

export type ApplicationFormBlockType = BaseBlock<BlockTypes.ApplicationFormBlock, IApplicationFormBlock>;

type CreatorsBannerColumn = {
  buttonAction: 'link' | 'application_form' | 'video';
  buttonLink: { link: string; text: string };
  dynamicForm: number | null;
  text: string;
  video: VideoItemType;
};

export type CreatorsBannerBlockType = BaseBlock<
  BlockTypes.CreatorsBanner,
  { preview: string; columns: CreatorsBannerColumn[] }
>;

export type CreatorsAboutProgramBlockType = BaseBlock<
  BlockTypes.CreatorsAboutProgram,
  { columns: { header: string; text: string }[]; description: string }
>;

export type CreatorsImage = BaseBlock<BlockTypes.CreatorsImage, string>;

export type CreatorsHowItWasBlockType = BaseBlock<BlockTypes.CreatorsHowItWas, { number: number; text: string }[]>;

export type CreatorsListingColumnsBlockType = BaseBlock<
  BlockTypes.CreatorsListingColumns,
  { header: string; points: string[] }[]
>;

export type CreatorsYearsBlockType = BaseBlock<
  BlockTypes.CreatorsYears,
  {
    year: number;
    button: {
      link: string;
      text: string;
    };
    description: string;
    listingColumns: { header: string; points: string[] }[];
  }[]
>;

export type CreatorsImagesBlockType = BaseBlock<BlockTypes.CreatorsImages, { image: string; text: string }[]>;

export type Block =
  | HugeSliderBlock
  | PortalDirectionBlock
  | CompanyGroupAnimatedBlock
  | GroupCompaniesBlock
  | ParagraphBlock
  | ServicesBannerBlock
  | TravelLineWidgetBlock
  | ProductsBlock
  | FreeContentBlock
  | CategoriesBlock
  | MoreTvOfferBlock
  | SportExpressOfferBlock
  | NewsMainImageBlock
  | NewsMainContentBlock
  | NewsPreviewBlock
  | NewsPagesBlock
  | AboutPortalDirectionsBlock
  | NewPreviewImageBlock
  | GalleryBlock
  | ImageWithDescriptionBlock
  | DoubleBannerBlock
  | StepsBlock
  | TabBlock
  | IconWithTextBlock
  | AboutProjectBannerBlock
  | NewProjectBannerBlock
  | TextBlock
  | GuidePagesBlock
  | GuideMainImageBlock
  | GuideMainContentBlock
  | NewsMainPagePreview
  | ApplicationMainPagePreview
  | IframeLinkBlock
  | CollapseBlock
  | HeaderBlock
  | CardsBlock
  | ChronologyBlock
  | PhotoCarouselBlock
  | BigBannerBlock
  | SmallBannerBlock
  | ArticleRightBlock
  | BigHeaderBlock
  | ImageBlock
  | FilterHeaderBlock
  | MagazineArticleBanner
  | IFrameSize
  | SimpleBannerBlock
  | DirectionsBlock
  | WithSortingBlock
  | MainPageImageBlock
  | GuidePreviewBlock
  | GuideMainPageImageBlock
  | VideoWithTextBlock
  | VideoGalleryBlock
  | AboutCenterTextBlock
  | AboutCenterHeaderBlock
  | AboutImageGalleryBlock
  | AboutColumnsBlock
  | AboutPartnersBlock
  | AboutProjectGroupsBlock
  | AboutOurPeopleBlock
  | ApplicationFormBlockType
  | CreatorsBannerBlockType
  | CreatorsAboutProgramBlockType
  | CreatorsImage
  | CreatorsHowItWasBlockType
  | CreatorsListingColumnsBlockType
  | CreatorsYearsBlockType
  | CreatorsImagesBlockType
  | VideoBeltBlockType;
