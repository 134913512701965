import React from 'react';
import styled from 'styled-components';

import { SubstitutedCategoryType } from 'web';

import { WarningPopup } from 'components/base-ui/warning-popup';
import {
  CategoryCardTypeEnum,
  ErrorScreenModel,
  FinanceCategoryCardTypeEnum,
  GuideCategoryCardTypeEnum,
  InsuranceCategoryCardTypeEnum,
  MediaCategoryCardTypeEnum,
} from 'services';

import { Block, BlockTypes } from 'utils/blocks';

import {
  AboutCenterHeader,
  AboutCenterText,
  AboutColumns,
  AboutImageGallery,
  AboutOurPeople,
  AboutPartners,
  AboutProjectGroup,
  AboutUsBanner,
} from 'components/about-us/components';

import { head } from 'lodash';
import {
  AboutPortalDirections,
  AboutProjectBanner,
  CategoriesRenderer,
  CollapseRenderer,
  CompanyGroupAnimated,
  CreatorsAboutProgramBlock,
  CreatorsBannerBlock,
  CreatorsHowItWasBlock,
  CreatorsImageBlock,
  CreatorsImagesBlock,
  CreatorsListingColumnsBlock,
  CreatorsYearsBlock,
  DoubleBanner,
  FreeContentRenderer,
  GalleryRenderer,
  GoodsRenderer,
  GroupCompaniesRenderer,
  GuidePagesRenderer,
  HeaderRenderer,
  HugeSliderRenderer,
  IconWithDescriptionRenderer,
  IframeLinkRenderer,
  ImageWithDescriptionRenderer,
  MediaOffer,
  NewsBanner,
  NewsContent,
  NewsWithApps,
  NewsPagesRenderer,
  ParagraphRenderer,
  PortalDirectionRenderer,
  ProductsRender,
  ServicesBannerRenderer,
  StepsRenderer,
  TabsRenderer,
  TextRenderer,
  TravelLineWidget,
  VideoBlock,
  VideoGallery,
} from '../index';
import { DirectionsRenderer } from '../directions';
import { GuidePreview } from '../guide-preview';
import { ApplicationFormBlock } from '../application-form-block';
import { VideoBand } from '../video-band';

const LoadingScreen = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

export type ProductType = 'services' | 'goods';

export type SectionOptions = Partial<{
  compact?: boolean;
  noLastOfTypeMargin?: boolean;
  borderLess?: boolean;
}>;

export type Options = Partial<{
  categoryType: SubstitutedCategoryType;
  cardType:
    | CategoryCardTypeEnum
    | FinanceCategoryCardTypeEnum
    | InsuranceCategoryCardTypeEnum
    | MediaCategoryCardTypeEnum
    | GuideCategoryCardTypeEnum;

  productType: ProductType;
  section: SectionOptions;
  productName?: string;
}>;

export type PropsWithSectionOptions<T> = T & { options?: SectionOptions };

interface BlocksRenderProps {
  blocks: Block[];
  loading?: boolean;
  options?: Options;
  errorScreen?: ErrorScreenModel;
  closeErrorScreenCallBack?: () => void;
  onShowLikeNewsModal?: (id: number) => void;
}

const BlocksRender: React.FC<BlocksRenderProps> = ({
  blocks = [],
  loading = false,
  options,
  errorScreen,
  closeErrorScreenCallBack,
  onShowLikeNewsModal,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  return loading ? (
    <LoadingScreen />
  ) : (
    <>
      {errorScreen && (
        <WarningPopup
          link={errorScreen?.detail ?? ''}
          description={errorScreen.content}
          header={errorScreen.title}
          handleCloseErrorScreen={closeErrorScreenCallBack}
        />
      )}
      {blocks.map((el) => {
        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (el.type) {
          case BlockTypes.HugeSlider:
            return <HugeSliderRenderer key={el.id} value={el.value} />;

          case BlockTypes.Paragraph:
            return <ParagraphRenderer key={el.id} value={el.value} options={options?.section} />;

          case BlockTypes.ServicesBanner:
            return <ServicesBannerRenderer key={el.id} value={el.value} />;

          case BlockTypes.Products:
            return el.value && options?.productType === 'services' ? (
              <ProductsRender key={el.id} cardType={options?.cardType} categoryType={options?.categoryType} />
            ) : (
              <GoodsRenderer key={el.id} />
            );

          case BlockTypes.GroupCompanies:
            return el.value && <GroupCompaniesRenderer key={el.id} />;

          case BlockTypes.PortalDirection:
            return el.value && <PortalDirectionRenderer key={el.id} />;

          case BlockTypes.FreeContent:
            return <FreeContentRenderer key={el.id} value={el.value} options={options?.section} />;

          case BlockTypes.Categories:
            return el.value && <CategoriesRenderer key={el.id} />;

          case BlockTypes.MoreTvOffer:
            return <MediaOffer data={el.value} key={el.id} type="MoreTv" />;

          case BlockTypes.SportExpressOffer:
            return <MediaOffer data={el.value} key={el.id} type="SportExpress" />;

          case BlockTypes.NewsMainImage:
            return <NewsBanner onShowLikeModal={onShowLikeNewsModal} image={el.value} key={el.id} />;

          case BlockTypes.NewsMainContent:
            return <NewsContent data={el.value} key={el.id} borderLess={options?.section?.borderLess} />;

          case BlockTypes.Gallery:
            return <GalleryRenderer items={el.value.imageItems} key={el.id} options={options?.section} />;

          case BlockTypes.Steps:
            return <StepsRenderer value={el.value} key={el.id} options={options?.section} />;

          case BlockTypes.ImageWithDescription:
            return <ImageWithDescriptionRenderer value={el.value} key={el.id} options={options?.section} />;

          case BlockTypes.Tab:
            return <TabsRenderer value={el.value} key={el.id} />;

          case BlockTypes.IconWithText:
            return <IconWithDescriptionRenderer value={el.value} key={el.id} />;

          case BlockTypes.Text:
            return <TextRenderer value={el.value} key={el.id} options={options?.section} />;

          case BlockTypes.DoubleBanner:
            return <DoubleBanner value={el.value} key={el.id} />;

          case BlockTypes.GuidePages:
            return el.value && <GuidePagesRenderer key={el.id} />;

          case BlockTypes.IframeLink:
            return <IframeLinkRenderer value={el.value} key={el.id} />;

          case BlockTypes.TravelLineWidget:
            return <TravelLineWidget name={options?.productName} />;

          case BlockTypes.NewsPreview:
            return (
              el.value && (
                <NewsWithApps
                  key={el.id}
                  news={el.value}
                  apps={Boolean(head(blocks.filter((b) => b.type === BlockTypes.ApplicationPreview))?.value)}
                />
              )
            );

          case BlockTypes.ApplicationPreview:
            return head(blocks.filter((b) => b.type === BlockTypes.NewsPreview))?.value ? null : (
              <NewsWithApps key={el.id} apps={el.value} news={false} />
            );

          case BlockTypes.NewsPages:
            return el.value && <NewsPagesRenderer key={el.id} />;

          case BlockTypes.Collapse:
            return <CollapseRenderer value={el.value} key={el.id} options={options?.section} />;

          case BlockTypes.AboutProjectBanner:
            return (
              <AboutProjectBanner text={el.value.text} image={el.value.image} key={el.id} options={options?.section} />
            );

          case BlockTypes.NewProjectBanner:
            return <AboutUsBanner text={el.value.preview} title={el.value.header} image={el.value.image} />;

          case BlockTypes.AboutCenterText:
            return <AboutCenterText text={el.value} key={el.id} />;

          case BlockTypes.AboutCenterHeader:
            return <AboutCenterHeader text={el.value} key={el.id} />;

          case BlockTypes.AboutImageGallery:
            return <AboutImageGallery images={el.value.imageItems.map((item) => item.image)} key={el.id} />;

          case BlockTypes.AboutColumns:
            return <AboutColumns header={el.value.header} columns={el.value.columns} key={el.id} />;

          case BlockTypes.AboutPartners:
            return (
              <AboutPartners header={el.value.header} text={el.value.text} partners={el.value.partners} key={el.id} />
            );

          case BlockTypes.AboutProjectGroups:
            return (
              <AboutProjectGroup header={el.value.header} text={el.value.text} people={el.value.people} key={el.id} />
            );

          case BlockTypes.AboutOurPeople:
            return (
              <AboutOurPeople header={el.value.header} text={el.value.text} elements={el.value.elements} key={el.id} />
            );

          case BlockTypes.CompanyGroupAnimated:
            return <CompanyGroupAnimated key={el.id} options={options?.section} />;

          case BlockTypes.Header:
            return <HeaderRenderer value={el.value} key={el.id} options={options?.section} />;

          case BlockTypes.AboutPortalDirections:
            return <AboutPortalDirections key={el.id} options={options?.section} />;

          case BlockTypes.Directions:
            return <DirectionsRenderer key={el.id} />;

          case BlockTypes.GuidePreview:
            return <GuidePreview key={el.id} />;

          case BlockTypes.VideoWithText:
            return <VideoBlock key={el.id} data={el.value} />;

          case BlockTypes.VideoGallery:
            return <VideoGallery key={el.id} data={el.value} />;

          case BlockTypes.VideoBelt:
            return <VideoBand key={el.id} data={el.value} />;

          case BlockTypes.ApplicationFormBlock:
            return (
              el && (
                <ApplicationFormBlock key={el.id} buttonText={el.value.text} formId={el.value.dynamicApplicationForm} />
              )
            );

          case BlockTypes.CreatorsBanner:
            return <CreatorsBannerBlock key={el.id} data={el.value} />;

          case BlockTypes.CreatorsAboutProgram:
            return (
              <CreatorsAboutProgramBlock
                key={el.id}
                description={el?.value?.description}
                columns={el?.value?.columns}
              />
            );

          case BlockTypes.CreatorsImage:
            return <CreatorsImageBlock key={el.id} imageLink={el.value} />;

          case BlockTypes.CreatorsHowItWas:
            return <CreatorsHowItWasBlock key={el.id} data={el.value} />;

          case BlockTypes.CreatorsListingColumns:
            return <CreatorsListingColumnsBlock key={el.id} data={el.value} />;

          case BlockTypes.CreatorsYears:
            return <CreatorsYearsBlock key={el.id} data={el.value} />;

          case BlockTypes.CreatorsImages:
            return <CreatorsImagesBlock key={el.id} data={el.value} />;

          // Dont render
          case BlockTypes.Preview:
          case BlockTypes.PreviewImage:
          case BlockTypes.WithSorting:
            return null;

          default:
            // eslint-disable-next-line no-console
            console.warn('Unhandled type of element');
            return null;
        }
      })}
    </>
  );
};

export default BlocksRender;
