import React from 'react';
import { Button } from 'shared/components';
import { StActions, StDetailButton } from './styled';
import { ActionList } from '../../index';

interface IProps {
  actions: ActionList[];
  offerButtonName: string;
  onDetailClick: () => void;
  onOfferClick: () => void;
}

export const Actions: React.FC<IProps> = (props) => {
  return (
    <StActions
      showActions={props.actions.includes(ActionList.showOfferButton) ||
      props.actions.includes(ActionList.showDetailButton)}
    >
      {props.actions.includes(ActionList.showDetailButton) && (
        <StDetailButton onClick={props.onDetailClick}>
          {props.actions.includes(ActionList.cardCollapsed) ? 'Подробнее' : 'Назад'}
        </StDetailButton>
      )}
      {props.actions.includes(ActionList.showOfferButton) && (
        <Button onClick={props.onOfferClick} suffix={false}>{props.offerButtonName}</Button>
      )}
    </StActions>
  );
};
