import React from 'react';
import { NewsItemProps } from 'web';
import { formatDate } from 'utils/date';
import { CardWrapper, Content, Date, ImageWrapper, Text } from './styles';

type NewsCardProps = {
  data: NewsItemProps;
};

export const NewsCard: React.FC<NewsCardProps> = ({ data }) => {
  return (
    <CardWrapper>
      <ImageWrapper bg={data.mainPageImage} />
      <Content>
        <Text>{data.paragraph?.header}</Text>
        <Date>{formatDate(data.date)}</Date>
      </Content>
    </CardWrapper>
  );
};
